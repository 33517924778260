import(/* webpackMode: "eager" */ "/app/entities/banners/ui/AppBanner/AppBanner.tsx");
import(/* webpackMode: "eager" */ "/app/entities/banners/ui/ru/AppBanner/AppBanner.tsx");
import(/* webpackMode: "eager" */ "/app/pageStyles/download-styled.tsx");
import(/* webpackMode: "eager" */ "/app/processes/layouts/hub-layout/styled.tsx");
import(/* webpackMode: "eager" */ "/app/widgets/download-app-banner/ui/DownloadAppBanner.tsx");
import(/* webpackMode: "eager" */ "/app/widgets/download-app-banner/ui/ru/DownloadAppBanner.tsx");
import(/* webpackMode: "eager" */ "/app/widgets/download-app/ui/DownloadAppBlock.tsx");
import(/* webpackMode: "eager" */ "/app/widgets/download-app/ui/ru/DownloadAppBlock.tsx");
import(/* webpackMode: "eager" */ "/app/widgets/download-features-block/ui/DownloadFeaturesBlock.tsx");
import(/* webpackMode: "eager" */ "/app/widgets/download-features-block/ui/ru/DownloadFeaturesBlock.tsx");
import(/* webpackMode: "eager" */ "/app/widgets/download-header/ui/ru/v1/DownloadHeader.tsx");
import(/* webpackMode: "eager" */ "/app/widgets/download-header/ui/v1/DownloadHeader.tsx");
import(/* webpackMode: "eager" */ "/app/widgets/download-header/ui/v2/DownloadHeader.tsx");
import(/* webpackMode: "eager" */ "/app/widgets/faq/ui/FaqBlock.tsx");
import(/* webpackMode: "eager" */ "/app/widgets/faq/ui/ru/FaqBlock.tsx");
import(/* webpackMode: "eager" */ "/app/widgets/footer/ui/Footer.tsx");
import(/* webpackMode: "eager" */ "/app/widgets/footer/ui/ru/Footer.tsx");
import(/* webpackMode: "eager" */ "/app/widgets/navbar/ui/Navbar.tsx");
import(/* webpackMode: "eager" */ "/app/widgets/navbar/ui/ru/Navbar.tsx");
import(/* webpackMode: "eager" */ "/app/widgets/nicegram-reviews/ui/Reviews.tsx");
import(/* webpackMode: "eager" */ "/app/widgets/nicegram-reviews/ui/ru/Reviews.tsx")