'use client';

import React from 'react';
import Image from 'next/image';
import phoneFront from '@/public/staticfiles/download/iphone-min.png';
import phoneBack from '@/public/staticfiles/download/iphone-skewed-min.webp';
import phonesMobile from '@/public/staticfiles/download/download-features-phones-mobile-min.png';
import * as SC from '../styled';

function DownloadFeaturesBlock() {
  return (
    <SC.Wrapper>
      <SC.FeatureList>
        <SC.FeatureWrapper>
          <SC.FeatureName>Управление несколькими аккаунтами</SC.FeatureName>
          <SC.FeatureText>С легкостью переключайтесь между личными и профессиональными аккаунтами</SC.FeatureText>
        </SC.FeatureWrapper>{' '}
        <SC.FeatureWrapper>
          <SC.FeatureName>Личный ИИ-ассистент</SC.FeatureName>
          <SC.FeatureText>Умный, быстро реагирует на запросы, всегда готов помочь</SC.FeatureText>
        </SC.FeatureWrapper>{' '}
        <SC.FeatureWrapper>
          <SC.FeatureName>Постоянная синхронизация</SC.FeatureName>
          <SC.FeatureText>Следите за обновлениями новейших решений и функций Telegram</SC.FeatureText>
        </SC.FeatureWrapper>
      </SC.FeatureList>
      <SC.ImagesWrapper>
        <Image height={900} width={450} src={phoneFront} alt="" />
        <Image height={900} width={450} src={phoneBack} alt="" />
        <Image id="phones-mobile" height={900} width={450} src={phonesMobile} alt="" />
      </SC.ImagesWrapper>
      <SC.FeatureList>
        <SC.FeatureWrapper>
          <SC.FeatureName>Встроенный переводчик</SC.FeatureName>
          <SC.FeatureText>Преодолевайте языковые барьеры с помощью мгновенных переводов</SC.FeatureText>
        </SC.FeatureWrapper>{' '}
        <SC.FeatureWrapper>
          <SC.FeatureName>Конфиденциальность высшего уровня</SC.FeatureName>
          <SC.FeatureText>
            Ваши разговоры под надежной защитой благодаря новейшим функциям конфиденциальности
          </SC.FeatureText>
        </SC.FeatureWrapper>{' '}
        <SC.FeatureWrapper>
          <SC.FeatureName>Nicegram – обмен сообщениями, дополненный инновациями</SC.FeatureName>
          <SC.FeatureText>Доступно более 20 функций</SC.FeatureText>
        </SC.FeatureWrapper>
      </SC.FeatureList>
    </SC.Wrapper>
  );
}

export default DownloadFeaturesBlock;
