'use client';

import React from 'react';
import Image from 'next/image';
import bannerImage from '@/public/staticfiles/download/download-app-banner-image-min.webp';
import { useAnalyticsContext } from '@/processes/contexts/analytics';
import { X_LEAD_EVENT_ID } from '@/processes/contexts/analytics/constants';
import { Card } from '@/shared/ui/card';
import { Button } from '@/shared/ui/button';

import * as SC from '../styled';

function DownloadAppBanner() {
  const { sendSafeMixpanelEvent, sendSafeFbqEvent, sendSafeXEvent, sendSafeYMEvent, sendSafeGtagEvent } =
    useAnalyticsContext();

  return (
    <SC.Wrapper>
      <Card>
        <Image height={470} width={460} src={bannerImage} alt="" />
        <div>
          <h4>
            Устанавливайте приложение — и получайте награды, выигрывайте реферальные бонусы, ловите бесплатные эйрдропы.
          </h4>
          <Button
            fullWidth
            as="a"
            href="https://nicegramap.onelink.me/gn7C/ybynsm7h"
            rel="noreferrer"
            target="_blank"
            onClick={() => {
              sendSafeFbqEvent('Lead');
              sendSafeXEvent(X_LEAD_EVENT_ID);
              sendSafeYMEvent('app_store_click');
              sendSafeGtagEvent('app_store_click');
              sendSafeMixpanelEvent('track', 'app_store_click');
            }}
            label="Скачать"
            gradient
          />
        </div>
      </Card>
    </SC.Wrapper>
  );
}

export default DownloadAppBanner;
